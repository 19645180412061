// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---content-pages-404-mdx": () => import("./../../../content/pages/404.mdx" /* webpackChunkName: "component---content-pages-404-mdx" */),
  "component---content-pages-aboutmudmuseum-mdx": () => import("./../../../content/pages/aboutmudmuseum.mdx" /* webpackChunkName: "component---content-pages-aboutmudmuseum-mdx" */),
  "component---content-pages-history-mdx": () => import("./../../../content/pages/history.mdx" /* webpackChunkName: "component---content-pages-history-mdx" */),
  "component---content-pages-index-mdx": () => import("./../../../content/pages/index.mdx" /* webpackChunkName: "component---content-pages-index-mdx" */),
  "component---content-pages-modernmuds-mdx": () => import("./../../../content/pages/modernmuds.mdx" /* webpackChunkName: "component---content-pages-modernmuds-mdx" */),
  "component---content-pages-sub-1-mdx": () => import("./../../../content/pages/sub-1.mdx" /* webpackChunkName: "component---content-pages-sub-1-mdx" */),
  "component---content-pages-sub-2-mdx": () => import("./../../../content/pages/sub-2.mdx" /* webpackChunkName: "component---content-pages-sub-2-mdx" */)
}

